var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c("el-breadcrumb-item", { attrs: { to: { path: "/warehouse" } } }, [
            _vm._v("仓储"),
          ]),
          _c("el-breadcrumb-item", [_vm._v("地址解析")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("el-row", { attrs: { gutter: 30, span: 24 } }, [
            _c(
              "div",
              { staticClass: "container info-card" },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticClass: "search-form",
                    attrs: {
                      rules: _vm.validateForm,
                      model: _vm.searchForm,
                      "label-width": "120px",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return (() => {}).apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item-input",
                        attrs: { label: "GLP单号", prop: "package_num" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "text",
                            clearable: "",
                            placeholder: "GLP订单/包裹单号",
                          },
                          model: {
                            value: _vm.searchForm.package_num,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "package_num", $$v)
                            },
                            expression: "searchForm.package_num",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item-input",
                        attrs: { label: "国家/地区", prop: "country" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { filterable: "" },
                            model: {
                              value: _vm.searchForm.country,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "country", $$v)
                              },
                              expression: "searchForm.country",
                            },
                          },
                          _vm._l(_vm.countryList, function (item, key) {
                            return _c("el-option", {
                              key: key,
                              attrs: {
                                label: `${item.name_cn}(${item.name})`,
                                value: item.code,
                                border: "",
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "address-service",
                        attrs: { label: "地址验证服务", prop: "country" },
                      },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: {
                              label: "melissa",
                              border: "",
                              size: "medium",
                            },
                            model: {
                              value: _vm.searchForm.channel,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "channel", $$v)
                              },
                              expression: "searchForm.channel",
                            },
                          },
                          [_vm._v("Melissa服务")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: {
                              label: "usps",
                              border: "",
                              size: "medium",
                              disabled: "US" !== _vm.searchForm.country,
                            },
                            model: {
                              value: _vm.searchForm.channel,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "channel", $$v)
                              },
                              expression: "searchForm.channel",
                            },
                          },
                          [_vm._v("USPS服务")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "州(state)" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "text",
                                clearable: "",
                                placeholder: "州(state)",
                              },
                              model: {
                                value: _vm.searchForm.state,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "state", $$v)
                                },
                                expression: "searchForm.state",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "城市(city)" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "text",
                                clearable: "",
                                placeholder: "城市(city)",
                              },
                              model: {
                                value: _vm.searchForm.city,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "city", $$v)
                                },
                                expression: "searchForm.city",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "邮编(zipcode)", required: "" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "text",
                                clearable: "",
                                placeholder: "邮编(zipcode)",
                              },
                              model: {
                                value: _vm.searchForm.postal_code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "postal_code", $$v)
                                },
                                expression: "searchForm.postal_code",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "地址1", required: "" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "text",
                                clearable: "",
                                placeholder: "地址1(Address Line 1)",
                              },
                              model: {
                                value: _vm.searchForm.address_line1,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "address_line1", $$v)
                                },
                                expression: "searchForm.address_line1",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "地址2" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "text",
                                clearable: "",
                                placeholder: "地址2(Address Line 2)",
                              },
                              model: {
                                value: _vm.searchForm.address_line2,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "address_line2", $$v)
                                },
                                expression: "searchForm.address_line2",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "z-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading.fullscreen.lock",
                                value: _vm.isLoading,
                                expression: "isLoading",
                                modifiers: { fullscreen: true, lock: true },
                              },
                            ],
                            attrs: {
                              type: "primary",
                              "element-loading-text": "加载中",
                              "element-loading-spinner": "el-icon-loading",
                              "element-loading-background":
                                "rgba(0, 0, 0, 0.8)",
                            },
                            on: { click: _vm.parseAddress },
                          },
                          [_vm._v("解析")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.addressResult.remind_messages.length ||
            _vm.addressResult.error_messages.length
              ? _c(
                  "div",
                  { staticClass: "container info-card" },
                  [
                    _vm._l(_vm.addressResult.remind_messages, function (item) {
                      return _c("el-alert", {
                        key: item,
                        attrs: { title: item, type: "info", closable: false },
                      })
                    }),
                    _vm._l(_vm.addressResult.error_messages, function (item) {
                      return _c("el-alert", {
                        key: item,
                        attrs: { title: item, type: "error", closable: false },
                      })
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "container info-card" },
              [
                _c(
                  "el-descriptions",
                  {
                    staticClass: "package-dimension",
                    attrs: { title: "解析地址结果", column: 1 },
                  },
                  [
                    _c(
                      "el-descriptions-item",
                      { attrs: { span: 2, label: "结果" } },
                      [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type:
                                _vm.addressResult.status == 0
                                  ? "danger"
                                  : "success",
                              size: "small",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.addressResult.status == 0
                                  ? "验证失败"
                                  : "验证成功"
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "国家/地区" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.addressResult.country
                              ? _vm.addressResult.country
                              : "-"
                          )
                        ),
                      ]
                    ),
                    _c("el-descriptions-item", { attrs: { label: "邮编" } }, [
                      _vm._v(_vm._s(_vm.addressResult.zipcode)),
                    ]),
                    _c("el-descriptions-item", { attrs: { label: "州" } }, [
                      _vm._v(_vm._s(_vm.addressResult.state)),
                    ]),
                    _c("el-descriptions-item", { attrs: { label: "城市" } }, [
                      _vm._v(" " + _vm._s(_vm.addressResult.city) + " "),
                    ]),
                    _c("el-descriptions-item", { attrs: { label: "地址1" } }, [
                      _vm._v(_vm._s(_vm.addressResult.address1)),
                    ]),
                    _c("el-descriptions-item", { attrs: { label: "地址2" } }, [
                      _vm._v(_vm._s(_vm.addressResult.address2 || "-")),
                    ]),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "完整地址" } },
                      [_vm._v(_vm._s(_vm.addressResult.format_address || "-"))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }