
import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { ElForm } from 'element-ui/types/form'
import ZButton from '@/components/base/z-button.vue'
import { sleep } from '@/utils/utils'

@Component({
	components: { ZButton }
})
export default class extends Emitter {
	public $refs!: {
		form: ElForm
	}
	private addressResult: any = {
		address1: '-',
		address2: '-',
		city: '-',
		state: '-',
		zipcode: '-',
		country: '',
		format_address: '-',
		status: 0,
		error_messages: [],
		remind_messages: []
	}
	private isLoading = false
	private countryData: any = {}
	private countryList: any = {}

	private searchForm: any = {
		str: '',
		country: 'US',
		package_num: '',
		address_line1: '',
		address_line2: '',
		city: '',
		state: '',
		postal_code: '',
		channel: 'melissa'
	}

	get isFreeForm() {
		return ['US', 'CA'].includes(this.searchForm.country)
	}

	private validateForm = {
		str: [{ required: false, message: '请输入需要解析的地址', trigger: 'blur' }]
	}
	getCountryIcon(country: string) {
		return this.countryData[country] ? this.countryData[country]['icon'] : ''
	}

	async getState() {
		const { data } = await this.$axios.get(
			'//res-cn.public.gblinker.com/gblinker/wechat_mini/data/states.json'
		)
		this.countryData = data
	}

	async getCountryList() {
		const { data } = await this.$axios.get(
			'https://res-cn.public.gblinker.com/gblinker/public_data/country-list.json'
		)
		this.countryList = data
	}

	created() {
		this.getCountryList()
		this.getState()
	}
	async beforeSubmit() {
		if (['US', 'CA'].includes(this.searchForm.country)) {
			if (
				!this.searchForm.str &&
				(!this.searchForm.postal_code || !this.searchForm.address_line1)
			) {
				this.$message.error('请输入邮编以及地址1')
				return false
			}
			return true
		}
		if (!this.searchForm.postal_code && !this.searchForm.address_line1) {
			this.$message.error('请输入邮编以及地址1')
			return false
		}
		return true
	}

	async parseAddress() {
		const isValidate = await this.beforeSubmit().catch(() => false)
		if (!isValidate) return
		this.isLoading = true
		const { country, str } = this.searchForm
		const { data } = await this.$axios
			.post('/v1/jobline/tools/address-check', {
				country: country,
				state: this.searchForm.state || undefined,
				city: this.searchForm.city || undefined,
				postal_code: this.searchForm.postal_code || undefined,
				address_line1: this.searchForm.address_line1 || undefined,
				address_line2: this.searchForm.address_line2 || undefined,
				free_form: str,
				channel: this.searchForm.channel
			})
			.catch(() => {
				return {
					data: null
				}
			})
			.finally(() => (this.isLoading = false))
		if (!data) {
			this.reSetAddressResult()
			return
		}
		this.addressResult = data[0]
		const { error_messages = [], remind_messages = [] } = data[0]
		this.addressResult.remind_messages = remind_messages
		this.addressResult.error_messages = error_messages

		for (const item of remind_messages) {
			await sleep(0.1)
			this.$notify({
				title: '提示',
				message: item,
				type: 'info',
				position: 'top-left'
			})
		}
		await sleep(0.2)
		for (const item of error_messages) {
			await sleep(0.1)
			this.$notify({
				title: '错误',
				message: item,
				type: 'error',
				position: 'top-left'
			})
		}
	}
	@Watch('searchForm.package_num')
	onInputLogisticsCode(value: string) {
		this.reSetAddressResult()
		if (!value) {
			this.searchForm = {
				str: '',
				country: 'US',
				package_num: '',
				address_line1: '',
				address_line2: '',
				city: '',
				state: '',
				postal_code: '',
				channel: 'melissa'
			}
			return
		}
		this.getPackageInfo()
	}
	@Watch('searchForm.country')
	onCountryChange(value: string) {
		this.reSetAddressResult()
		if (value && value != 'US') {
			this.searchForm.channel = 'melissa'
		}
		return
	}
	async reSetAddressResult() {
		this.addressResult = {
			address1: '-',
			address2: '-',
			city: '-',
			state: '-',
			zipcode: '-',
			country: '-',
			format_address: '-',
			status: 0,
			error_messages: [],
			remind_messages: []
		}
	}

	async getPackageInfo() {
		const { package_num } = this.searchForm
		if (!package_num) return
		if (!/^GLP\d+/.test(package_num)) {
			this.$message.error('请输入正确的 订单/包裹号')
			return
		}
		const { data } = await this.$axios
			.get(`/v1/jobline/${package_num}/package-info`, {})
			.catch(() => {
				return {
					data: null
				}
			})
		this.reSetAddressResult()
		if (data) {
			this.searchForm.str = `${data.address1} ${data.address2 || ''}, ${
				data.city
			}, ${data.state} ${data.zipcode}, ${data.country}`
			this.searchForm.country = data.country
			this.searchForm.city = data.city
			this.searchForm.state = data.state || ''
			this.searchForm.postal_code = data.zipcode || ''
			this.searchForm.address_line1 = data.address1 || ''
			this.searchForm.address_line2 = data.address2 || ''
		}
	}
}
